body {
  overflow-x: hidden;
  background: #f5f6fa 0 0 no-repeat padding-box;
}
.z-100 {
  z-index: 100;
}
.no-pad {
  padding: 0;
}
.no-rpad {
  padding-right: 0;
}
.no-lpad {
  padding-left: 0;
}
/* a:hover {
  color: inherit;
  text-decoration: none;
} */

.btn-outline-secondary {
  color: rgb(204, 204, 204);
  border-width: 0.05em;
  border-style: solid;
  border-color: rgb(204, 204, 204);
}
.list-group-item.active {
  color: inherit !important;
  background-color: inherit !important;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: rgb(204, 204, 204);
  border-color: rgb(204, 204, 204);
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.bg-help {
  background-color: #f0f0f0;
}

/*.modal {*/
/*display: block !important;*/
/*}*/

/*.modal-dialog {
  overflow-y: initial !important;
}*/

.modal-backdrop {
  opacity: 0.7 !important;
}

.node-ui-modal-body {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.help-body {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.select-error {
  border: 1px solid #fb6340 !important;
  border-radius: 0.25em;
}
.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}
.react-select-wrapper {
  width: 29.75em;
  border: 1px solid;
  border-radius: 4px;
}
.tc-editor-wf-toggle-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  width: 13em;
  padding: 0 1em;
}
.options-dropdown {
  color: #5e72e4;
  border: 0.1em solid #5e72e4;
  border-radius: 0.25em;
  font-size: 0.9em;
  font-weight: 600;
}
.form-control {
  /*height: 39.25px !important;*/
  /*padding: 0.525rem 0.55rem !important;*/
}

.ant-steps-vertical .ant-steps-item-content {
  overflow: visible;
}

.color-onBoarding-background {
  /*background-color: rgb(244, 245, 250) !important;*/
}

.color-onBoarding-background > .modal-content {
  background-color: rgb(244, 245, 250) !important;
}

.color-onBoarding-background > .modal-content > .modal-body {
  background-color: rgb(244, 245, 250) !important;
}

.font-larger {
  font-size: 1.5rem;
}

.font-smaller {
  font-size: 0.5rem;
}

.font-normal {
  font-size: 1rem;
}

.dropdown-item.active {
  background-color: #1d8cf8;
  color: #ffffff !important;
}
.dropdown-item.active:hover {
  background-color: #1d8cf8;
}
.background-white {
  background-color: white;
}
.custom-container {
  /*border-radius: 0.5rem;*/
  /*border: 2px solid #d1d1d1;*/
  box-shadow: 0 0 7px -5px #444444;
  background-color: #ffffff;
  margin-bottom: 1rem;
}
.border-bottom {
  border: 0;
  border-color: rgb(226, 224, 224);
}
.list-group li:last-child {
  border-bottom: 0px !important;
}
.color-blue {
  color: #3358f4;
}
/* .container-fluid {
  padding-left: 0;
  padding-right: 0;
} */
.btn-primary:focus {
  color: white !important;
}
.simplebar-content li {
  background-color: #f6f6fa;
  /* opacity: 1; */
}
.white-content p {
  line-height: initial;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading {
  animation: rotation 1s linear infinite;
}

.alert-primary {
  background-color: #1d8cf8;
  color: #f6f6fa;
}

.alert-link {
  color: #f6f6fa !important;
}

.pagination .page-item {
  border: 1px #0f335e !important;
}

.page-item .page-link {
  color: #0f335e;
}

.modal-header {
  padding: 24px;
}

.modal-content > .modal-header {
  border-bottom: 1px solid #dbdcdf;
}

.text-strikethrough {
  text-decoration: line-through;
}
.list-style-none {
  list-style: none;
}
.square-box {
  width: 10px;
  height: 10px;
}
.utilisation-legend-container {
  max-height: 50px;
  overflow-y: scroll;
}

.nestedInput label:hover {
  color: #f88205;
}

.nestedInput label:active {
  color: #1d8cf8;
}

.nestedInputSelected label:hover label:active {
  color: #1d8cf8;
}

.navbar-brand.logo {
  height: 41px;
}
.navbar-brand.logo img {
  height: 100%;
}

.shimmer {
  height: 20px;
  width: 100px;
  border-radius: 3px;
  background: linear-gradient(270deg, #c1c1c1, #f3f3f3, #d7d4d4);
  background-size: 600% 600%;
  -webkit-animation: shimmer-effect 2s ease infinite;
  -moz-animation: shimmer-effect 2s ease infinite;
  animation: shimmer-effect 2s ease infinite;
}

@-webkit-keyframes shimmer-effect {
  0% {
    background-position: 0 40%;
  }
  50% {
    background-position: 100% 61%;
  }
  100% {
    background-position: 0 40%;
  }
}
@-moz-keyframes shimmer-effect {
  0% {
    background-position: 0 40%;
  }
  50% {
    background-position: 100% 61%;
  }
  100% {
    background-position: 0 40%;
  }
}
@keyframes shimmer-effect {
  0% {
    background-position: 0 40%;
  }
  50% {
    background-position: 100% 61%;
  }
  100% {
    background-position: 0 40%;
  }
}

.ql-container.ql-snow {
  min-height: 200px;
}

.signup-page.heading-lead {
  line-height: 1.6;
  letter-spacing: 0.05rem;
}
