/*Generic*/
:root {
  --totalcloud-main-blue: #1d8cf8;
  --totalcloud-main-blue-dark: #1f63ae;
  --totalcloud-main-blue-light: #3498f9;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'ProximaNova', sans-serif;
}

img {
  max-width: 100%;
}

button {
  outline: none !important;
}

body.ReactModal__Body--open {
  overflow: hidden !important;
}

/*Common*/

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-64 {
  font-size: 64px;
}

.font-48 {
  font-size: 48px;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-8 {
  font-size: 8px !important;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-offwhite {
  color: rgba(255, 255, 255, 0.5);
}

.vh-100 {
  height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

#graph-id-graph-wrapper {
  position: absolute;
  right: 0;
  left: 250px;
  bottom: 0;
  top: 10%;
  overflow: scroll;
}

#react-dag-container {
  position: absolute !important;
  left: 20vw;
  /* margin-left: 20vw; */
  /* height: calc(100vh - 200px); */
  /* left: 300px; */
  /* top: 11%; */
  /* right: -2rem; */
  width: auto !important;
  overflow: scroll !important;
}

.dashboard-date-picker {
  border-color: #0f335e !important;
  border-radius: 4px !important;
  border-width: 0.5px !important;
  height: 24px !important;
  padding-left: 8px;
}

.dashboard-date-picker::-webkit-input-placeholder {
  color: #0f335e !important;
  padding-left: 8px !important;
}

.react-tiny-popover-container {
  overflow: inherit !important;
}

.dashboard-date-picker-container {
  background-color: white;
  padding: 12px;
  border-radius: 3px;
  cursor: pointer;
}

.dashboard-date-picker-wrapper {
  padding: 4px;
}

.dashboard-date-picker-icon {
  width: 1em;
  position: absolute;
  margin-left: -1.2em;
  margin-top: 0.3em;
}

.dashboard-date-picker-apply-btn {
  border-radius: 5px;
  padding: 4px;
  background-color: #0f335e;
  font-size: 12px;
  width: 100%;
  height: 30px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}

.dashboard-date-picker-label {
  cursor: pointer;
}

a {
  color: #1d8cf8;
}

.input-section {
  margin-right: 2em;
}

.output-section {
  margin-left: 2em;
}

.scrollarea:hover .scrollbar-container {
  opacity: 5 !important;
}

.scrollarea .scrollbar-container.vertical .scrollbar {
  background-color: #ddd !important;
}

.success-green {
  color: #00debc !important;
}

.dashboard-card-title {
  color: #0098f0 !important;
  font-weight: normal;
}

.dashboard-card-count-title {
  color: #1d253b;
  font-size: 14px;
  font-weight: normal;
}

.dashboard-card-button {
  font-size: 14px;
  font-weight: normal;
}

.disboard-card-count {
  font-weight: 500;
  color: #0098f0 !important;
}

.dashboard-card-text {
  font-weight: lighter;
}

.custom-blue {
  color: #1d8cf8 !important;
}

.custom-checkbox-input {
  width: 20px;
  height: 20px;
}

.onBoarding-columns {
  border-right: 1px solid rgb(209, 207, 207);
}

.custom-light-grey {
  color: #595959;
}

.custom-button-width {
  width: 20%;
}

.board-content {
  min-height: 50vh;
  position: relative;
  padding-left: 4%;
  padding-right: 4%;
}

.submit-board {
  position: absolute;
  bottom: 5%;
  width: 92%;
  /* left: 42%; */
}

.board-button-text {
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.board-sub-title {
  font-size: 14px;
}

@media only screen and (min-device-width: 1200px) {
  .board-button-text {
    font-size: 14px !important;
  }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1199px) {
  .board-button-text {
    font-size: 12px !important;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 899px) {
  .board-button-text {
    font-size: 8.5px !important;
  }

  .board-sub-title {
    font-size: 12px !important;
  }
}

.sidebar .list-group-item {
  background-color: transparent;
  border-radius: 0;
}

.sidebar-logo .list-group-item:nth-child(1) {
  padding: 0.5rem 0rem !important;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  border-bottom: 1px solid rgb(226, 224, 224) !important;
}

.most-used-tag {
  border-radius: 0.7rem;
  background: #fd5d93 0% 0% no-repeat padding-box;
  font-size: 0.6rem;
}

.dashboard-card {
  border-radius: 3% !important;
  border: 0.1rem solid #d1d1d1 !important;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0 !important;
  /* padding: 0.7rem 0rem 0.7rem 0; */
  height: 45px;
  z-index: 100;
  background-color: #fff;
  -webkit-box-shadow: -2px 21px 42px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 21px 42px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 20px 0px rgba(141, 139, 139, 0.75);
}

.notification-bar {
  z-index: 999;
  position: fixed;
  top: 2.8rem;
  right: 0.2rem;
  min-width: 500px;
}

/*.navbar .photo {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}
.navbar .photo img {
  width: 100%;
}
.dropdown-toggle {
  padding-left: 0rem !important;
}
.navbar .navbar-text {
  color: #ffffff;
}
.dropdown-navbar {
} */
.side-bar-toggle {
  display: none;
  left: 100%;
  transform: translateX(-50%);
  top: 35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 5;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer !important;
  font-weight: lighter;
  transition: all 0.15s ease-out;
}

.sidebar-scroll {
  /* overflow-y: scroll;
          height: 100%;
          width: 100%;
          box-sizing: content-box;
          scrollbar-width: none !important; */
  /* max-height: 100vh; */
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}

.sidebar {
  z-index: 5;
  width: 14vw;
  margin: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: var(--totalcloud-main-blue) !important;
}

.selected-text {
  white-space: nowrap;
}

.dashboard-content {
  margin-top: 100px;
}

.help-section .nav-link {
  background-color: darkgrey !important;
  border: 2px solid darkgrey !important;
}

.help-section .nav-item .active {
  border: 2px solid white !important;
}

.tc-tab .nav-item .active {
  border-color: #0c2646 !important;
}

.input-group .form-control {
  border: 1px solid rgba(29, 37, 59, 0.5);
}

.form-control:focus {
  background-color: transparent;
}

.input-group .is-invalid {
  border: 1px solid red !important;
}

.border-segment-report {
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid grey;
  margin-top: 22.5px;
}

.start-time {
  border: 1px solid rgba(150, 150, 151, 0.5) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.date-select {
  border-radius: 0 !important;
  padding: 10px !important;
}

.calendar-icon {
  color: grey;
}

.workflow-name-input:focus {
  outline: none;
}

/* .react-bootstrap-table tbody {
  display: block;
  height: 500px;
  overflow-y: scroll;
}
.react-bootstrap-table thead tr {
  display: block;
}
.react-bootstrap-table thead,
.react-bootstrap-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.react-bootstrap-table table {
  width: 100%;
} */
/* .react-bootstrap-table {
  height: 500px;
  overflow-y: scroll;
}
.react-bootstrap-table tbody {
  height: auto;
} */

.hook-url {
  overflow-wrap: break-word;
}

.sidebar .list-group-item {
  padding-left: 8px;
  padding-right: 8px;
}

.no-account-text {
  line-height: 1.5em;
}

.account-common-description {
  letter-spacing: 0;
  color: #4a5162;
}

@media (max-height: 650px) {
  .account-common-description {
    bottom: 5%;
  }

  .help-account {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
}

.help-account {
  margin-top: 5%;
  margin-bottom: 5%;
}

.help-account p {
  align-self: center;
  letter-spacing: 0;
  color: #4a5162;
  opacity: 1;
}

.login {
  min-height: 100vh;
}

.login-heading-description {
  font-family: 'LeagueSpartan', sans-serif;
}

.login-subheading-description {
  font-family: 'SanchezRegular', sans-serif;
  width: 90%;
}

/* Group Select */
.group-dropdown-arrow::before {
  content: '\027A4';
  float: left;
  color: gray;
}

.group-dropdown:hover .group-dropdown-arrow::before {
  content: '\25be';
  float: left;
  color: gray;
}

.option-editor-menu {
  min-width: 650px;
}

.page-link {
  color: inherit;
}

.pagination .page-item .page-link:hover {
  color: inherit;
}

.category-dropdown-menu {
  z-index: 1;
}

.lineAfterBefore {
  line-height: 0.5;
  text-align: center;
}

.lineAfterBefore span {
  display: inline-block;
  position: relative;
}

.lineAfterBefore span:before,
.lineAfterBefore span:after {
  content: '';
  position: absolute;
  height: 5px;
  border-bottom: 1px solid #cad1d7;
  border-top: 1px solid #cad1d7;
  top: 0;
  width: 200px;
}

.lineAfterBefore span:before {
  right: 100%;
  margin-right: 15px;
}

.lineAfterBefore span:after {
  left: 100%;
  margin-left: 15px;
}

.table .table {
  background-color: white;
}

.borderless .table > tbody > tr {
  border-style: hidden !important;
}

.heading-none .table > thead {
  display: none;
}

.compact-table td {
  padding: 0px !important;
}

.reset-expansion-style {
  background-color: white;
}

.tc-table-hide-hover .table > tbody tr:hover {
  background-color: white;
}

.custom-trigger-button {
  width: 40%;
}

.trigger-action-data {
  width: 40%;
}

.trigger-action-data > .row {
  border-bottom: 1px solid rgb(202, 202, 202);
}

.account-text-wrap {
  overflow-wrap: break-word;
}

.input-group-append .btn {
  z-index: 0;
}

.textarea-line-break {
  line-break: anywhere;
}

.tc-editor-modal {
  max-width: 2000px;
  width: 98%;
  margin: 10px;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.ace_editor.ace_autocomplete {
  width: 50%;
}

.highlight-trial {
  background-color: #a7d6f1;
}

.underline-breadcrum:hover {
  text-decoration: underline;
}

.breadcrumb a {
  color: inherit;
  text-decoration: inherit;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
}

.breadcrumb ol {
  margin-bottom: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '\2023';
  color: rgb(141, 138, 138);
  font-weight: 400;
}

.breadcrumb .active a {
  font-weight: bold;
}

.breadcrumb .active {
  font-weight: bold;
}

.breadcrumb .breadcrumb-item {
  color: rgb(151, 151, 151);
  font-size: 15px;
}

.output-execution-details {
  height: 60vh;
  overflow: scroll;
}

.output-execution-details pre {
  white-space: pre-wrap;
  word-break: keep-all;
}

.table-info-text {
  color: silver;
}

div.jsoneditor-tree {
  resize: vertical;
}

.react-bootstrap-table-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tooltip {
  pointer-events: none;
}

.react-bootstrap-table-pagination .col-md-6 {
  display: flex;
  justify-content: flex-end;
}

.color-red {
  color: red;
}

.color-green {
  color: green;
}

.memberarn-text-wrap {
  overflow-wrap: anywhere;
}

.account-dropdown-menu {
  max-height: 150px;
  overflow: auto;
}

.min-50vh {
  min-height: 50vh;
}

.table {
  margin-bottom: 0;
}

.bottom-border {
  border: 0;
  border-bottom: 1px solid grey;
}

.bottom-border:focus {
  outline: 0;
}

.bottom-border::placeholder {
  font-style: italic;
}

.sidebar-nav-links {
  transition: all 0.2s ease-in;
  border-radius: 5px !important;
}

.sidebar-nav-links:hover {
  background: var(--totalcloud-main-blue-dark);
  opacity: 1;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.table-no-fixed-layout {
  table-layout: auto !important;
}

.react-bootstrap-table table {
  /*table-layout: auto !important;*/
  /*display: block;*/
  width: 100%;
  overflow: scroll;
}

.react-bootstrap-table table tr {
  display: table-row;
}

.react-bootstrap-table table td {
  display: table-cell;
  max-width: 300px;
}

.overflow-scroll {
  overflow-x: scroll;
}

.resize-table th {
  /*position: -webkit-sticky;*/
  /*position: sticky;*/
  top: 0;
  position: relative;
  border: 2px solid #dee2e6;
  user-select: none;
}

.resize-table th:focus {
  outline: none;
}

.resize-table .resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0;
  width: 4px;
  cursor: col-resize;
}

.resize-table .resize-handle:hover,
.resize-table .header--being-resized .resize-handle {
  opacity: 0.5;
}

.resize-table th:hover .resize-handle {
  opacity: 0.3;
}

.pagination {
  margin-bottom: 0;
}

.resize-table {
  min-height: 390px;
}
