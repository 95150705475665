@import './assets/styles/style';

@import './assets/styles/description';

@import './assets/fonts/fontstyle';

@mixin proximaFonts {
  font-family: 'Proxima Nova Regular', sans-serif;
}

body {
  //margin: 0;
  font-family: 'Proxima Nova Regular', sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

code {
  //font-family: 'Proxima Nova';
}
