@font-face {
  font-family: 'navIcon';
  src: url('../fonts/navIcon.ttf?9wyign') format('truetype'),
    url('../fonts/navIcon.woff?9wyign') format('woff'),
    url('../fonts/navIcon.svg?9wyign#navIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='navIcon-'],
[class*=' navIcon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'navIcon', serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navIcon-accounts:before {
  content: '\e900';
}
.navIcon-billing:before {
  content: '\e901';
}
.navIcon-dashboard:before {
  content: '\e902';
}
.navIcon-editor:before {
  content: '\e903';
}
.navIcon-executions:before {
  content: '\e904';
}
.navIcon-settings:before {
  content: '\e905';
}
.navIcon-support:before {
  content: '\e906';
}
.navIcon-templates:before {
  content: '\e907';
}
.navIcon-workflows:before {
  content: '\e908';
}
.navIcon-filter-applied:before {
  content: '\e909';
}
