.executionListScrollBar {
  /* position: absolute;
    top: 30px;
    width: 100%; */
  overflow-x: hidden;
  height: 56vh;
}

.simplebar-scrollbar {
  right: 0.12em;
  width: 0.3em;
  min-height: 0.62em;
}

.simplebar-scrollbar::before {
  position: absolute;
  content: '';
  background: #000;
  border-radius: 0.43em;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
