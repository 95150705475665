.description {
  font-size: 0.9vw;
  // font-size: 1vw;

  a {
    width: 40%;

    img {
      width: 100%;
    }
  }

  h3,
  li {
    font-family: 'Proxima Nova Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
  }

  h3 {
    margin-top: 1em;
  }

  ul {
    padding: 1.25em;

    li {
      font-size: 1.25em;
      line-height: 1.875em;
      margin-top: 0.5em;
      list-style-type: none;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0.6em;
        left: -1.2em;
        height: 0.5em;
        width: 0.5em;
        bottom: 0.5em;
        border-radius: 50%;
        background: #3579dc;
      }

      &:nth-of-type(1) {
        // margin-top: 3.18em;
      }

      span {
        // margin-left: 0.625em;
      }
    }
  }

  img {
    margin-top: 3.18em;
  }

  .group {
    width: 100%;
    height: 100%;
  }

  img {
    &:nth-of-type(2) {
      width: 100%;
      margin-top: 4.375em;

      @media screen and (max-width: 992px) {
        margin-top: 1.875em;
      }
    }
  }

  // @media screen and (min-width: 992px) {
  //   padding-left: 20%;
  // }
}
