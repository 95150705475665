@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Regular.otf');
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('../fonts/Proxima Nova Semibold.otf');
}

@font-face {
  font-family: 'LeagueSpartan';
  src: url('../fonts/LeagueSpartan.otf');
}
@font-face {
  font-family: 'SanchezRegular';
  src: url('../fonts/Sanchez-Regular.ttf');
}
