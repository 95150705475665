$default: #ffffff !default;
$primary: #0098f0 !default;
$orange: #f6821c !default;
$purple: #7c4dca !default;
$secondary: #f4f5f7 !default;
$success: #2a9f1e !default;
$info: #0098f0 !default;
$warning: #dda912 !default;
$danger: #d41414 !default;
$dark: #4b4d4e !default;
$black: #222a42 !default;
$primary-bg: #3579dc !default;
$theme-colors: (
  'orange': $orange,
  'purple': $purple,
  'primary-bg': $primary-bg,
);

$font-family-base: 'ProximaNova', sans-serif !default;
$font-family-alt: 'ProximaNova', sans-serif !default;

$input-disabled-bg: $secondary !default;

$form-feedback-invalid-bg: lighten($danger, 15%);
$form-feedback-invalid-color: $danger !default;
$headings-font-family: 'ProximaNova-Semibold', sans-serif;
$heading-font-weight: 700;
$label-margin-bottom: 0.25rem;
$form-group-margin-bottom: 0.5rem;
@import 'src/assets/black-dashboard-pro/scss/black-dashboard-pro-react';
