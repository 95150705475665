@font-face {
  font-family: 'tc-new_design';
  src: url('../fonts/tc-new_design.eot?kz0fj0');
  src: url('../fonts/tc-new_design.eot?kz0fj0#iefix')
      format('embedded-opentype'),
    url('../fonts/tc-new_design.ttf?kz0fj0') format('truetype'),
    url('../fonts/tc-new_design.woff?kz0fj0') format('woff'),
    url('../fonts/tc-new_design.svg?kz0fj0#tc-new_design') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='new_icon-'],
[class*=' new_icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tc-new_design' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.new_icon-auto-rem:before {
  content: '\e90d';
}
.new_icon-Utilization:before {
  content: '\e90e';
}
.new_icon-accounts:before {
  content: '\e901';
}
.new_icon-dashboard:before {
  content: '\e902';
}
.new_icon-editor:before {
  content: '\e903';
}
.new_icon-insights:before {
  content: '\e904';
}
.new_icon-operations:before {
  content: '\e905';
}
.new_icon-reports:before {
  content: '\e906';
}
.new_icon-request-feature:before {
  content: '\e907';
}
.new_icon-Scheduling:before {
  content: '\e908';
}
.new_icon-templates:before {
  content: '\e90a';
}
.new_icon-user:before {
  content: '\e90b';
}
.new_icon-workflows:before {
  content: '\e90c';
}
.new_icon-dots-horizontal-triple:before {
  content: '\e909';
}
.new_icon-dots-horizontal-triple1:before {
  content: '\e900';
}
