$background_color: #3579dc;
$error_color: #ff0000;
$default_color: #fff;

@mixin checkbox {
  background: rgba(40, 40, 40, 0.2);
  appearance: none;
  color: black;
  border: 0.06em solid #fff;
  border-radius: 0.25em;
  width: 1.0625em;
  height: 1.0625em;
  cursor: pointer;

  &:checked {
    background: $background_color;
  }

  &:checked:after {
    content: '';
    position: absolute;
    outline: none;
    color: #1c2125;
  }
}

body {
  //font-size: 0.95vw;
}

.login {
  padding: 2.5% 0;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  .login-form {
    position: relative;
    width: 30em;
    background: $background_color;
    box-shadow: 0 0.4375em 1.875em 0 rgba(0, 0, 0, 0.61);
    min-height: 30em;
    font-family: 'Proxima Nova Light', sans-serif;

    @media screen and (max-width: 768px) {
      font-size: 1em;
      box-shadow: none;
      width: 100%;
    }

    .btn {
      font-size: 0.875em;
    }

    form {
      font-family: 'Proxima Nova Light', sans-serif;
      margin: 15% auto;
      width: 75%;
      padding-top: 0;

      .form-control {
        height: 2.522em;
        padding: 0.625em 0.75em;
        border-radius: 0.25em;
      }
    }

    h5,
    h6,
    h3,
    h4 {
      color: #fff;
      margin: auto;
    }

    h6 {
      font-size: 1em;
    }

    h5 {
      @media screen and (max-width: 992px) {
        font-size: 1em;
      }

      @media screen and (min-width: 768) {
        font-size: 0.95vw;
      }
    }

    input:-webkit-autofill {
      background-color: red !important;
    }

    h3 {
      // font-weight: 600;
      // line-height: 1.75em;
      // font-size: 1.75em;
      font-weight: 600;
      font-size: 1.5em;
      margin: 0 auto;
      line-height: 1.2;
    }

    .title-row {
      // padding-top: 30%;
      margin-bottom: 1.562em;

      h4 {
        font-size: 1.5em;
        margin: 0 auto;
        width: 100%;
        line-height: 1.2;
        font-weight: 500;
      }
    }

    .failed-message {
      p {
        margin: auto;
        font-weight: 100;
        color: $error_color;
        font-size: 0.9em;
        margin-top: 0.5em;
      }
    }

    .default-message {
      p {
        margin: auto;
        font-weight: 100;
        color: $default_color;
        font-size: 0.9em;
        margin-top: 0.5em;
      }
    }

    .login-button {
      width: 100%;
      text-transform: uppercase;
      color: $background_color;
      background: #fff;
      font-weight: 700;
      letter-spacing: 0.0218em;
      padding: 0.625em 1.875em;
      font-family: 'Proxima Nova Semibold';
    }

    .register-form {
      padding-bottom: 30%;

      .register-link {
        margin: auto;
      }

      .register-button {
        @extend .login-button;
        font-weight: 700;
        letter-spacing: 0.0218em;
        text-align: center;
        padding: 0.625em 1.875em;
      }
    }

    .password {
      position: relative;
      color: #fff;

      span {
        font-family: 'proxima-nova, sans-serif';
        font-weight: 100;
        position: absolute;
        right: 0.625em;
        top: 0.625em;
      }

      #show-password {
        position: absolute;
        right: 7.5em;
        top: 0.8125em;

        input {
          @include checkbox();
        }
      }

      small {
        color: $error_color;
        text-align: right;
        width: 100%;
        margin-top: 0.5em;
      }
    }

    .password-hint {
      margin-left: -0.625em;
      margin-bottom: 0.5em;

      .error {
        display: inline-flex;
        color: $error_color;

        span {
          margin-left: 0.625em;
          margin-top: 0.25em;
          font-size: 0.8vw;
        }
      }

      .valid {
        display: inline-flex;
        color: white;

        span {
          margin-left: 0.625em;
          margin-top: 0.25em;
          font-size: 0.8vw;
        }
      }

      :last-child {
        margin-bottom: 0.3em;
      }
    }

    .email {
      small {
        color: $error_color;
        text-align: right;
        width: 100%;
        margin-top: 0.5em;
        font-size: 90%;
      }
    }

    .actname {
      small {
        color: $error_color;
        text-align: right;
        width: 100%;
        margin-top: 0.5em;
        font-size: 90%;
      }
    }

    .agreements {
      // margin-top: 1em !important;

      // margin-left: 15px;
      input {
        @include checkbox();
      }

      small {
        margin-left: 0.625em;
        font-size: 90%;
      }

      span {
        color: #fff;
        margin-left: 0.625em;
        font-weight: 100;
        font-size: 90%;
      }

      img {
        width: 1.025em;
      }
    }

    .password-view {
      width: 1.5em;
      position: absolute;
      right: 0.625em;
      top: 0.475em;
    }

    .keepmelogin {
      input {
        @include checkbox();
      }

      span {
        color: #fff;
        margin-left: 0.625em;
        font-weight: 100;
        font-size: 0.89em;
      }

      img {
        width: 1.025em;
      }

      .forgot-password {
        @media screen and (max-width: 768px) {
          margin: 0.8rem 0;
        }

        .forgot-link {
          font-family: 'Proxima Nova Semibold';
          text-transform: uppercase;
          top: 0.625em;
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    .awstipsform {
      input {
        @include checkbox();
      }

      label,
      small {
        color: #fff;
        margin-left: 0.625em;
        font-weight: 100;
        font-size: 95%;
        line-height: 1.5;
      }

      span {
        text-align: center;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .service-content {
      h6 {
        color: $error_color;
      }
    }

    .have-account {
      // padding-top: 20%;
      position: absolute;
      bottom: 5.5%;
      width: 100%;
      justify-content: center;

      a {
        font-weight: 600;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        margin-left: 0.937em;
        border-bottom: 0.0625em solid rgba(255, 255, 255, 0.3);
      }
    }

    input::placeholder {
      color: #fff;
      opacity: 0.7;
    }

    .context-input {
      font-family: 'proxima nova Thin';
      background: $background_color;
      color: #fff;
      border: 0.0625em solid #fff;
      font-size: 1em;
      font-weight: 800;
    }

    .go-to-login-page {
      // padding-top: 40.25em;
      h5 {
        position: absolute;
        bottom: 5.5%;
        font-size: 1em;
        width: 80%;
        text-align: center;
      }

      a {
        font-weight: 600;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        margin-left: 0.3em;
        border-bottom: 0.0625em solid rgba(255, 255, 255, 0.3);
      }
    }

    .verification {
      margin-bottom: 1rem;
      margin: 15% auto;
      padding-top: 0;

      .title {
        font-family: 'Proxima Nova Semibold';
      }

      .content {
        width: 75%;
        margin: auto;
      }
    }

    @media screen and (max-width: 1201px) {
      // margin-top: 30%;
    }

    @media screen and (max-width: 992px) {
      // margin-top: 60%;
    }

    @media screen and (max-width: 768px) {
      margin-top: 5%;
    }
  }
}
