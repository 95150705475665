.dropdown-menu {
  border: 0;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: $border-radius-xs;
  @include transitions($fast-transition-time, $transition-linear);

  &.dropdown-menu-right {
    &:before,
    &:after {
      left: auto;
      right: 20px;
    }
  }

  i {
    margin-right: 5px;
    position: relative;
    top: 1px;
  }

  .tim-icons {
    margin-right: 10px;
    position: relative;
    top: 4px;
    font-size: 18px;
    margin-top: -5px;
    opacity: 0.5;
  }

  .dropdown-item {
    &.active,
    &:active {
      color: inherit;
    }
  }
  .dropdown-item,
  .bootstrap-select &.inner li a {
    font-size: $font-size-sm;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-top: 5px;
    @include transitions($fast-transition-time, $transition-linear);

    &:hover,
    &:focus {
      background-color: $opacity-gray-3;
    }

    &.disabled,
    &:disabled {
      color: $default-opacity;

      &:hover,
      &:focus {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  .dropdown-divider {
    background-color: $opacity-gray-5;
  }

  .dropdown-header:not([href]):not([tabindex]) {
    color: $default-opacity;
    font-size: $font-size-xs;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }

  &.dropdown-primary {
    @include dropdown-colors(
      darken($primary, 3%),
      $opacity-8,
      $white,
      $opacity-2
    );
  }

  &.dropdown-info {
    @include dropdown-colors(darken($info, 3%), $opacity-8, $white, $opacity-2);
  }

  &.dropdown-danger {
    @include dropdown-colors(
      darken($danger, 3%),
      $opacity-8,
      $white,
      $opacity-2
    );
  }

  &.dropdown-success {
    @include dropdown-colors(
      darken($success, 3%),
      $opacity-8,
      $white,
      $opacity-2
    );
  }

  &.dropdown-warning {
    @include dropdown-colors(
      darken($warning, 3%),
      $opacity-8,
      $white,
      $opacity-2
    );
  }

  .dropdown.show &,
  .bootstrap-select.show &:not(.inner),
  &.bootstrap-datetimepicker-widget.top.open,
  &.bootstrap-datetimepicker-widget.bottom.open,
  .dropup.show:not(.bootstrap-select) &,
  .navbar .dropdown.show & {
    @include opacity(1);
    visibility: visible;
  }
}

.btn {
  cursor: pointer;

  &.dropdown-toggle[data-toggle='dropdown'] {
    padding: 10px;
    margin: 0;
    margin-bottom: 5px;

    &:after {
      content: '';
      margin-left: 5px;
    }
  }

  span.bs-caret {
    display: none;
  }

  &.btn-link {
    &.dropdown-toggle {
      height: 22px;
      padding: 0;
      margin-right: 5px;
    }
  }
}

.dropdown-toggle:after {
  content: unset;
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: none;
}

//// Dropown: Sizes

.dropdown-menu-sm {
  min-width: 100px;
  border: $border-radius-lg;
}

.dropdown-menu-lg {
  min-width: 260px;
  border-radius: $border-radius-lg;
}

.dropdown-menu-xl {
  min-width: 450px;
  border-radius: $border-radius-lg;
}

@include media-breakpoint-down(md) {
  .button-dropdown {
    display: none;
  }
  .dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

@include media-breakpoint-up(lg) {
  .dropdown-menu .dropdown-item {
    color: $black;
  }
}
