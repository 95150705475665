@font-face {
  font-family: 'tcicon';
  src: url('../fonts/tcicon.ttf?mvbshy') format('truetype'),
    url('../fonts/tcicon.woff?mvbshy') format('woff'),
    url('../fonts/tcicon.svg?mvbshy#tcicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='tc-icon'],
[class*=' tc-icon'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tcicon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tc-icon-action:before {
  content: '\e900';
}
.tc-icon-custom:before {
  content: '\e901';
}
.tc-icon-delay:before {
  content: '\e902';
}
.tc-icon-enrichment:before {
  content: '\e903';
}
.tc-icon-filter:before {
  content: '\e904';
}
.tc-icon-logical:before {
  content: '\e905';
}
.tc-icon-monitoring:before {
  content: '\e906';
}
.tc-icon-notification:before {
  content: '\e907';
}
.tc-icon-report:before {
  content: '\e908';
}
.tc-icon-resource:before {
  content: '\e909';
}
.tc-icon-trigger:before {
  content: '\e90a';
}
.tc-icon-user-approval:before {
  content: '\e90b';
}
