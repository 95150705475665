// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.nav-tabs {
  border: 0;
  padding: $padding-base-vertical $padding-base-horizontal;

  > .nav-item {
    > .nav-link {
      color: $dark;
      margin: 0;
      margin-right: 5px;
      background-color: $transparent-bg;
      border-radius: 30px;
      padding: $padding-btn-vertical $padding-round-horizontal;
      line-height: $line-height-sm;

      &:hover {
        background-color: $transparent-bg;
        border: 1px solid darken($black, 5%);
        color: $primary;
      }

      &.active {
        border: 1px solid $white;
        border-radius: 30px;
        color: $primary;
      }

      i.tim-icons {
        font-size: 14px;
        position: relative;
        top: 1px;
        margin-right: 3px;
      }
    }

    &.disabled > .nav-link,
    &.disabled > .nav-link:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.nav-tabs-neutral {
    > .nav-item {
      > .nav-link {
        color: $dark;

        &.active {
          border-color: $opacity-5;
          color: $dark;
        }
      }
    }
  }

  &.nav-tabs-primary {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $primary;
          color: $primary;
        }
      }
    }
  }

  &.nav-tabs-info {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $info;
          color: $info;
        }
      }
    }
  }

  &.nav-tabs-danger {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $danger;
          color: $danger;
        }
      }
    }
  }

  &.nav-tabs-warning {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $warning;
          color: $warning;
        }
      }
    }
  }

  &.nav-tabs-success {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $success;
          color: $success;
        }
      }
    }
  }
}
