@font-face {
  font-family: 'tc-custom-font';
  src: url('../fonts/tc-custom-font.ttf?l2ld7o') format('truetype'),
    url('../fonts/tc-custom-font.woff?l2ld7o') format('woff'),
    url('../fonts/tc-custom-font.svg?l2ld7o#tc-custom-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='master-tc-'],
[class*=' master-tc-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tc-custom-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.master-tc-dots-horizontal-triple1:before {
  content: '\e900';
}
.master-tc-account:before {
  content: '\e901';
}
.master-tc-list:before {
  content: '\e902';
}
.master-tc-region:before {
  content: '\e903';
}
.master-tc-continuous:before {
  content: '\e904';
}
.master-tc-filter:before {
  content: '\e905';
}
.master-tc-sort:before {
  content: '\e906';
}
.master-tc-accounts:before {
  content: '\e907';
}
.master-tc-dashboard:before {
  content: '\e908';
}
.master-tc-editor:before {
  content: '\e909';
}
.master-tc-insights:before {
  content: '\e90a';
}
.master-tc-operations:before {
  content: '\e90b';
}
.master-tc-reports:before {
  content: '\e90c';
}
.master-tc-request-feature:before {
  content: '\e90d';
}
.master-tc-Scheduling:before {
  content: '\e90e';
}
.master-tc-templates:before {
  content: '\e90f';
}
.master-tc-user:before {
  content: '\e910';
}
.master-tc-workflows:before {
  content: '\e911';
}
.master-tc-auto-rem:before {
  content: '\e912';
}
.master-tc-Utilization:before {
  content: '\e913';
}
