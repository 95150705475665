/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Proxima Nova Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Regular'),
    url('ProximaNova-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Thin'), url('ProximaNovaT-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Light';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Light'), url('ProximaNova-Light.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Semibold'),
    url('ProximaNova-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Bold'), url('ProximaNova-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Extrabold';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Extrabold'),
    url('ProximaNova-Extrabld.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Black';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Black'), url('ProximaNova-Black.woff') format('woff');
}
